import { useCallback, useEffect, useRef, useState } from "react"
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { InstantSearch, SearchBox, Configure, SortBy, Stats } from 'react-instantsearch-dom'
import { CurrentRefinements, ClearRefinements, connectRefinementList } from 'react-instantsearch-dom'
import { t } from "i18next"

import { ArrayUniqe, LangFix, translationsForSearchBox, ShowLoader, HideLoader, scrollTop } from "../functions"
import { xCompanyID, searchClient, priceLineID, pricelineIDRecommended, bookletsTypeID } from "../config"
import { getRequestOptions, getMeilisearchIndexName } from "../helpers/apiHelper"
import { setIsClickOnAllProductsInHeader, setIsClickOnBannerAllProducts, setIsRefreshMarketingCampaigns } from '../reduxToolkit/universalSlice'

import { Breadcrumbs } from "../breadcrumbs"
import { CustomInfiniteHits } from '../components/CustomInfiniteHits'
import { Banner200 } from '../banner200'
import { ModalProductPage } from '../components/ModalProductPage'

import { ReactComponent as HeartDarkGrey } from '../assets/svg/heartdarkgrey.svg'
import { ReactComponent as BookDarkGrey } from '../assets/svg/bookdarkgrey.svg'
import { ReactComponent as TagDarkGrey } from '../assets/svg/tagdarkgrey.svg'
import { ReactComponent as Percent } from '../assets/svg/percent.svg'
import { ReactComponent as RefreshDarkGrey } from '../assets/svg/refreshdarkgrey.svg'
import { ReactComponent as RaquoWhite } from '../assets/svg/raquowhite.svg'
import { ReactComponent as GridView } from '../assets/svg/gridview.svg'
import { ReactComponent as ListView } from '../assets/svg/listview.svg'

import '../assets/css/filter.css'

let segmentationInsert = ''
if (localStorage.getItem('account.segmentationids')) segmentationInsert = ArrayUniqe(localStorage.getItem('account.segmentationids').split(',')).join(',')

// const filterNames = ['Brands', 'Categories', 'Countries', 'Families', 'Kinds', 'Company', 'ItemPrices', 'itemStocks']
const filterNames = [ 'Families','Kinds', 'Categories', 'Brands', 'Countries']
const filterTranslations = {
    'Brands': t('niki.b2b.menu.brands'),
    'Categories': t('niki.b2b.allproducts.categories'),
    'Countries': t('niki.b2b.allproducts.countries'),
    'Families': t('niki.b2b.allproducts.families'),
    'Kinds': t('niki.b2b.allproducts.kinds')
}
const filterRefinement = {
    'Brands': 'brand.name',
    'Categories': `categories.${LangFix(localStorage.getItem('locale'))}`,
    'Countries': `country.name_translated.${LangFix(localStorage.getItem('locale'))}`,
    'Families': 'family',
    'Kinds': `kinds.${LangFix(localStorage.getItem('locale'))}`,
    'Company': 'company_id',
    'ItemPrices': 'itemPrices',
    'itemStocks': 'itemStocks',
}
const defaultFilterRefinement = {}

const returnDefaultShowFilters = () => {
    return {
        'allitems': '',
        'favorites': `id IN [${localStorage.getItem('favorites')}]`,
        'booklet': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.booklet}]`
            : `is_booklet=true`,
        'new': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.new}]`
            : `is_new=true`,
        'discount': localStorage.getItem('marketingCampaignIds')
            ? `marketingCampaigns IN [${JSON.parse(localStorage.getItem('marketingCampaignIds'))?.promo}]`
            : `is_discount=true`,
        'purchased': `id IN [${localStorage.getItem('history.ids')}]`
    }
}

const startFacetFilters = [
    `company_id=${xCompanyID}`,
    `itemPrices.${priceLineID}.priceline_id=${priceLineID}`,
    `itemStocks.source_warehouse_id=${localStorage.getItem('warehouse.id')}`,
    `itemSegmentations IN [${segmentationInsert}]`,
]

!localStorage.getItem('product.view') && localStorage.setItem('product.view','grid')
localStorage.setItem('allproducts.show','')
localStorage.setItem('allproducts.search','')
localStorage.setItem('allproducts.brands', '')

const ButtonAction = ({ title, show, content, ClickTopFilterButton, showFilters }) => {
    return (
        <button
            data-title={t(title)}
            className="top_filter_button"
            onClick={ClickTopFilterButton}
            data-filter={showFilters[show]}
            data-show={show}
        >{content}</button>
    )
}

/* banner */
const now = new Date()
const todayIs = `${String(now.getDate()).padStart(2, '0')}.${String(now.getMonth() + 1).padStart(2, '0')}.${now.getFullYear()}`
let showBanner = true
if (localStorage.getItem('banner200.closed.when')) {
    if (localStorage.getItem('banner200.closed.when') === todayIs) {
        showBanner = false
        localStorage.setItem('banner200.closed','true')
    } else {
        localStorage.setItem('banner200.closed','false')
        showBanner = true
    }
}
!showBanner && document.body.classList.add('banner200closed')
/* banner */

const AlphabeticalRefinementList = connectRefinementList(({ items, refine }) => {
    const sortedItems = items.sort((a, b) =>
        a.label.localeCompare(b.label)
    )

    return (
        <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
            {sortedItems.map(item => (
                <li key={item.value} className="ais-RefinementList-item">
                    <label className="ais-RefinementList-label">
                        <input
                            type="checkbox"
                            className="ais-RefinementList-checkbox"
                            value={item.value}
                            checked={item.isRefined}
                            onChange={event => {
                            refine(item.value)
                            }}
                        />
                        <span className="ais-RefinementList-labelText">{item.label}</span>
                        <span className="ais-RefinementList-count">{item.count}</span>
                    </label>
                </li>
            ))}
            </ul>
        </div>
  )
})

const UpdateBrowserURL = () => {
    const currentURL = new URL(window.location.href)

    const searchValue = localStorage.getItem('allproducts.search')
    if (searchValue && searchValue.length > 0) {
        currentURL.searchParams.set('search', searchValue)
    } else {
        currentURL.searchParams.delete('search')
    }
    
    const showValue = localStorage.getItem('allproducts.show')
    if (showValue === 'allitems' || showValue === '') {
        currentURL.searchParams.delete('show')
    } else {
        currentURL.searchParams.set('show',showValue)
    }
    
    const brandsValue = localStorage.getItem('allproducts.brands')
    if (brandsValue === '') {
        currentURL.searchParams.delete('brand')
    } else {
        currentURL.searchParams.set('brand',brandsValue)
    }

    window.history.replaceState(null, '', currentURL)
    localStorage.setItem('url.back',`/all-products/${currentURL.search}`)
}

export const AllProducts = () => {

    const defaultShowFilters = returnDefaultShowFilters()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const [brand, setBrand] = useState("")
    const booklet = searchParams.get("booklet") || null
    const banner = searchParams.get("banner") || null
    const IDs = searchParams.get("ids") || null
    const search = searchParams.get("search") || null
    const show = searchParams.get("show") || null
    const queryURL = searchParams.get("?search=") || ''
    const defaultSearchQuery = search ? search : queryURL.length > 8 ? queryURL : ''
    const isUpdatePageFromHeader = useSelector(state => state.universalContent.isClickOnAllProductsInHeader)
    const isClickOnBannerAllProducts = useSelector(state => state.universalContent.isClickOnBannerAllProducts)
    const isRefreshMarketingCampaigns = useSelector(state => state.universalContent.isRefreshMarketingCampaigns)
    const isReadyFavorites = useSelector(state => state.universalContent.isReadyFavorites)

    if (brand) {
        const currentUrl = window.location
        const urlHasLEGENDAIRY = currentUrl.search.includes('?brand=') && currentUrl.search.includes(currentUrl.search.slice(7))
        const checkIsBrands = localStorage.getItem('allproducts.brands') === ''
        defaultFilterRefinement['Brands'] = checkIsBrands ? urlHasLEGENDAIRY ? brand : '' : brand
        localStorage.setItem('allproducts.brands', checkIsBrands ? urlHasLEGENDAIRY ? brand : '' : brand)
    }

    const [showFilters, setShowFilters] = useState(defaultShowFilters)
    const currentShowValue = show ? show : 'allitems'
    const defaultFacet = showFilters[currentShowValue]

    const leftFilterEl = useRef(null)
    const showHideFilterArrowEl = useRef(null)
    const gridViewEl = useRef(null)
    const listViewEl = useRef(null)
    const mainWrapperEl = useRef(null)
    const buttonRefAllProducts = useRef(null)

    const [currentShow, setCurrentShow] = useState(currentShowValue)
    const [trigerCurrentShow, setTrigerCurrentShow] = useState(false)
    
    const [selectedFilters, setSelectedFilters] = useState(0)
    const [allProducts, setAllProducts] = useState(0)
    const [productView, setProductView] = useState(localStorage.getItem('product.view'))
    const [showHideInnerText, setShowHideInnerText] = useState(t('niki.b2b.allproducts.showfilters'))

    const [topFilters, setTopFilters] = useState('')
    const [inStockFilters, setInStockFilters] = useState('')
    const [purchasedFilters, setPurchasedFilters] = useState('')
    const [isResetProductsPerPage, setResetProductsPerPage] = useState(false)
    const [isClickFavorites, setClickFavorites] = useState(false)
    const [isSearchProduct, setSearchProduct] = useState(false)
    // eslint-disable-next-line
    const [checkingForBrands, setCheckingForBrands] = useState(false)
    
    const [facetFilters, setFacetFilters] = useState([...startFacetFilters, defaultFacet])
    const [filters, setFilters] = useState('')
    const [productId, setProductId] = useState('')

    const clearLocalStorageDependency = () => {
        localStorage.setItem('booklet.data', JSON.stringify({}))
        localStorage.setItem('banner.data', JSON.stringify({}))
    }

    useEffect(() => {
        if (isRefreshMarketingCampaigns) {
            setShowFilters(returnDefaultShowFilters())
            dispatch(setIsRefreshMarketingCampaigns(false))
        }
        // eslint-disable-next-line
    }, [isRefreshMarketingCampaigns])

    useEffect(() => {
        if (localStorage.getItem('history.ids')) {
            setShowFilters(prev => ({ ...prev, purchased: `id IN [${localStorage.getItem('history.ids')}]` }))
        }
    }, [])

    useEffect(() => {
        if (isClickOnBannerAllProducts) {   
            setFilters('')
            clearLocalStorageDependency()
            setResetProductsPerPage(prev => !prev)
            dispatch(setIsClickOnBannerAllProducts(false))
            const currentUrl = window.location
            const wichBrand = currentUrl?.search?.slice(currentUrl?.search?.indexOf('=') + 1)
            setBrand(`${wichBrand}`?.replace('%20', ' '))
            defaultFilterRefinement['Brands'] = `${wichBrand}`
        }
        // eslint-disable-next-line
    }, [isClickOnBannerAllProducts])
    
    useEffect(() => {
        if (isClickFavorites || isReadyFavorites) {
            const refer = localStorage.getItem('favorites')
            showFilters.favorites.slice(5).split(',').length !== refer.split(',').length && setShowFilters(prev => ({...prev, favorites: `id IN [${refer}]`}))
        }
        // eslint-disable-next-line
    }, [isClickFavorites, isReadyFavorites])
    
    useEffect(() => {
        if (booklet) {
            localStorage.setItem('allproducts.brands', '')
            const bookletData = JSON.parse(localStorage.getItem('booklet.data'))
            if (Object.keys(bookletData).length > 0) {
                bookletData?.productsIdArray?.length && setFilters(`id IN [${bookletData.productsIdArray}]`)
            } else if (Object.keys(bookletData).length === 0) {
                ShowLoader()
                fetch(`${process.env.REACT_APP_API_DOMAIN}/marketing-campaigns?include=items&search=typeableType.id:${bookletsTypeID}`, getRequestOptions())
                    .then(response => response.json())
                    .then(json => {
                        if (json.data) {
                            const data = json.data
                            data.length > 0 && data.forEach((e) => {
                                if (e.id === booklet) {
                                    const getProductsIdArray = (items) => {
                                        return items.map(item => item.id)
                                    }
                                    e.items?.data?.length && setFilters(`id IN [${getProductsIdArray(e.items.data)}]`)
                                }
                            })
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => HideLoader())
            }
        } else if (banner) {
            localStorage.setItem('allproducts.brands', '')
            const bannerData = JSON.parse(localStorage.getItem('banner.data'))
            if (Object.keys(bannerData).length > 0) {
                bannerData?.productsIdArray?.length && setFilters(`id IN [${bannerData.productsIdArray}]`)
            } else if (Object.keys(bannerData).length === 0) {
                const data = JSON.parse(localStorage.getItem('banners_data')) || []
                if (data.length > 0) {
                    ShowLoader()
                    const searchBanner = data.filter(el => el.json && el.json.includes(banner))
                    const arrayBannersFormJson = searchBanner.length === 1 && JSON.parse(searchBanner[0].json).images
                    const bannerData = arrayBannersFormJson.filter(el => el[banner])
                    bannerData.length === 1 && setFilters(`id IN [${bannerData[0][banner].slice(4).split(',')}]`)
                    HideLoader()
                }
            }
        }
        // eslint-disable-next-line
    }, [booklet, banner])
    
    // logic for parsing IDs from banners or an array of product IDs in url format "all-products/?ids=LwPz878BrwyoAOlY,..."
    useEffect(() => {
        if (IDs && IDs?.length > 16) { setFilters(`id IN [${IDs}]`) }
    }, [IDs])

    useEffect(() => {
        if (brand && !selectedFilters && !checkingForBrands) {
            setSelectedFilters(1)
        }
    }, [brand, selectedFilters, checkingForBrands])
    
    useEffect(() => {
        setBrand(searchParams.get("brand"))

        return () => {
            if (localStorage.getItem('is_on_product_card') === 'false') {
                clearLocalStorageDependency()
                localStorage.setItem('allproducts.show', '')
                localStorage.setItem('allproducts.search', '')
                localStorage.setItem('allproducts.brands', '')
                localStorage.setItem('url.back', '')
                defaultFilterRefinement['Brands'] = ''
            }
        }
        // eslint-disable-next-line
    }, [])

    const resetSearchProducts = () => {
        setSelectedFilters(0)
        setSearchProduct(prev => !prev)
        setResetProductsPerPage(prev => !prev)
    }

    const ClickTopFilterButton = e => {
        resetSearchProducts()
        const thisValue = e.target.dataset.filter
        setTrigerCurrentShow(prev => !prev)
        setCurrentShow(e.target.dataset.show)
        localStorage.setItem('allproducts.show', e.target.dataset.show)
        setTopFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, inStockFilters, purchasedFilters])
        UpdateBrowserURL()
    }

    const ChangeInStock = e => {
        scrollTop()
        resetSearchProducts()
        const thisValue = e.target.checked ? 'is_in_stock=true' : ''
        setInStockFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, topFilters, purchasedFilters])
    }

    const ChangePurchased = e => {
        scrollTop()
        resetSearchProducts()
        const thisValue = e.target.checked ? `id IN [${localStorage.getItem('history.ids')}]` : ''
        setPurchasedFilters(thisValue)
        setFacetFilters([...startFacetFilters, thisValue, topFilters, inStockFilters])
    }

    const onClearCurrentSelecion = () => {
        navigate('/all-products/')
        setFilters('')
        clearLocalStorageDependency()
    }

    useEffect(() => {
        mainWrapperEl.current.className = `wrapper ${productView}`
        document.querySelector(`.products_listing`).className = `products_listing ${productView}`
        if (productView === 'grid') {
            gridViewEl.current.classList.add('active')
            listViewEl.current.classList.remove('active')
        } else {
            listViewEl.current.classList.add('active')
            gridViewEl.current.classList.remove('active')
        }
        localStorage.setItem('product.view', productView)
    }, [productView, trigerCurrentShow])

    useEffect(() => {
        document.querySelector('.top_filter_button.active') && document.querySelector('.top_filter_button.active').classList.remove('active')
        document.querySelector(`.top_filter_button[data-show="${currentShow}"]`).classList.add('active')
    }, [currentShow, trigerCurrentShow])

    const ShowHideLeftFilter = () => {
        if (leftFilterEl.current.classList.contains('active')) {
            leftFilterEl.current.classList.remove('active')
            setShowHideInnerText(t('niki.b2b.allproducts.showfilters'))
            showHideFilterArrowEl.current.classList.remove('turned')
        } else {
            leftFilterEl.current.classList.add('active')
            setShowHideInnerText(t('niki.b2b.allproducts.hidefilters'))
            showHideFilterArrowEl.current.classList.add('turned')
        }
    }
    const FilterUnitSearch = e => {
        const thisValue = e.target.value.trim().toLowerCase()
        const filter_unit = e.target.parentNode.parentNode
        if (thisValue.length > 0) {
            filter_unit.querySelectorAll('.ais-RefinementList-list li').forEach(el => el.style.display = 'none')
            filter_unit.querySelectorAll('.ais-RefinementList-list li').forEach(el => {
                const innerLiText = el.querySelector('.ais-RefinementList-labelText').innerText.trim()
                if (innerLiText.toLowerCase().includes(thisValue)) el.style.display = 'block'
            })
        } else {
            filter_unit.querySelectorAll('.ais-RefinementList-list li').forEach(el => el.style.display = 'block')
        }
    }
    const ExpandCollapseFilterUnit = e => {
        const FilterUnit = e.target.parentNode
        e.target.classList.toggle('turned')
        FilterUnit.classList.toggle('collapsed')
    }
    const GridListSwitcher = e => {
        setProductView(e.target.dataset.value)
    }
    const ResetResults = useCallback((e) => {
        let selectedFiltersCount = 0
        const refinements = e.refinementList
        for (let key in refinements) {
            selectedFiltersCount += refinements[key].length
            if (key === 'brand.name') {
                if (refinements[key].length === 1) {
                    localStorage.setItem('allproducts.brands', refinements[key][0])
                } else {
                    localStorage.setItem('allproducts.brands', '')
                }
            }
        }
        setSelectedFilters(selectedFiltersCount)
        setCheckingForBrands(true)
        UpdateBrowserURL()
    }, [])

    const searchInputChange = e => {
        if (!e.target.value && defaultSearchQuery && searchParams?.has('search')) {
            searchParams.delete('search')
            localStorage.setItem('allproducts.search', '')
        }
        localStorage.setItem('allproducts.search', e.target.value)
        UpdateBrowserURL()
    }

    useEffect(() => {
        if (isUpdatePageFromHeader) {
            dispatch(setIsClickOnAllProductsInHeader(false))
            setInStockFilters('')
            setPurchasedFilters('')
            clearLocalStorageDependency()
            localStorage.setItem('allproducts.show', '')
            localStorage.setItem('allproducts.search', '')
            localStorage.setItem('allproducts.brands', '')
            defaultFilterRefinement['Brands'] = ''
            setSelectedFilters(0)
            setBrand('')
            buttonRefAllProducts.current.click()
            onClearCurrentSelecion()
        }
        // eslint-disable-next-line
    }, [isUpdatePageFromHeader])

    return (
        <>
            <Banner200 />
            <InstantSearch
                indexName={getMeilisearchIndexName("items")}
                searchClient={searchClient}
                onSearchStateChange={ResetResults}
                searchParameters={filters}
                key={isResetProductsPerPage}
            >
                <div className="breadcrumbs_search wrapper">
                    <Breadcrumbs itemlist={[{ 'url': '/all-products/', 'title': t('niki.b2b.menu.allproducts') }]} noclasswrapper={true} />
                    <div>
                        {isSearchProduct
                            ? <SearchBox
                                onChange={searchInputChange}
                                defaultRefinement={defaultSearchQuery}
                                translations={translationsForSearchBox()}
                                ignoreEmptyQuery={true}
                            />
                            : <div class="ais-SearchBox">
                                <form novalidate="" class="ais-SearchBox-form" action="" role="search">
                                    <input type="search" placeholder="Поиск" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" required="" maxlength="512" class="ais-SearchBox-input" value="" onChange={setSearchProduct(true)} />
                                    <button type="submit" title="Поиск" class="ais-SearchBox-submit">
                                        <svg class="ais-SearchBox-submitIcon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 40 40" aria-hidden="true">
                                            <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path>
                                        </svg>
                                    </button>
                                    <button type="reset" title="Clear the search query." class="ais-SearchBox-reset" hidden="">
                                        <svg class="ais-SearchBox-resetIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="10" height="10" aria-hidden="true"><path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
                                        </svg>
                                    </button>
                                </form>
                            </div>
                        }
                    </div>
                </div>
                {(booklet || banner || IDs?.length > 16) && <div className="top_filter_buttons wrapper">
                    <button
                        className="btn text_transform_capitalize"
                        onClick={onClearCurrentSelecion}
                    >{t('niki.common.clearButtonText')} {t('niki.b2b.promotions.current')}</button>
                </div>}
                <div className="top_filter_buttons wrapper">
                    <button className="top_filter_button" onClick={ClickTopFilterButton} data-filter="" data-show="allitems" ref={buttonRefAllProducts}>{t('niki.b2b.allproducts.allitems')}</button>
                    <ButtonAction title={'niki.b2b.menu.favorites'} show={"favorites"} content={<HeartDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} />
                    <ButtonAction title={'niki.b2b.allproducts.marketingcampaigns'} show={"booklet"} content={<BookDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} />
                    <ButtonAction title={'niki.b2b.allproducts.newitems'} show={"new"} content={<TagDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} />
                    <ButtonAction title={'niki.b2b.allproducts.discounts'} show={"discount"} content={<Percent />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} />
                    <ButtonAction title={'niki.b2b.allproducts.alreadypurchased'} show={"purchased"} content={<RefreshDarkGrey />} ClickTopFilterButton={ClickTopFilterButton} showFilters={showFilters} />
                </div>

                <div className="sticky_filter wrapper">
                    <div className="grid_filter__selected">
                        <ClearRefinements translations={{ reset: t('niki.b2b.allproducts.clear_all_filters') }} />
                        <CurrentRefinements />
                    </div>
                    <div className="grid_filter__status">
                        <div className="nowrap">
                            <strong>1</strong> {t('niki.b2b.to')} <strong className="products_on_page">{0}</strong> {t('niki.b2b.header.of')} <strong><Stats translations={{ stats(nbHits) { setAllProducts(nbHits); return nbHits } }} /></strong> {t('niki.b2b.cart.items')}</div>
                        <div className="center">
                            <label className="grid_filter__status__label stock_input"><input type="checkbox" onChange={ChangeInStock} checked={!!inStockFilters} />{t('niki.b2b.allproducts.instock')}</label>
                            <label className="grid_filter__status__label purchased_input"><input type="checkbox" onChange={ChangePurchased} checked={!!purchasedFilters} />{t('niki.b2b.allproducts.alreadypurchased')}</label>
                        </div>
                        <div>
                            {t('niki.b2b.product.sortby')}:
                            <SortBy
                                defaultRefinement={`${getMeilisearchIndexName("items")}:source_item_id:asc`}
                                items={[
                                    {
                                        value: `${getMeilisearchIndexName("items")}:source_item_id:asc`,
                                        label: t('niki.b2b.allproducts.ref_product')
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:matchcode:asc`,
                                        label: t('niki.b2b.allproducts.family') + " ↗"
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:matchcode:desc`,
                                        label: t('niki.b2b.allproducts.family') + " ↘"
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:itemPrices.${priceLineID}.unit_price:asc`,
                                        label: t('niki.b2b.allproducts.price') + " ↗"
                                    },
                                    {
                                        value: `${getMeilisearchIndexName("items")}:itemPrices.${priceLineID}.unit_price:desc`,
                                        label: t('niki.b2b.allproducts.price') + " ↘"
                                    }
                                ]}
                            />
                        </div>
                        <div>
                            <button className="grid_filter__status__view" ref={listViewEl} data-value="list" onClick={GridListSwitcher}><ListView /></button>
                            <button className="grid_filter__status__view" ref={gridViewEl} data-value="grid" onClick={GridListSwitcher}><GridView /></button>
                        </div>
                    </div>
                </div>
    
                <div className="wrapper" ref={mainWrapperEl}>
                    <Configure
                        facetFilters={facetFilters}
                        hitsPerPage={12}
                        filters={filters}
                        attributesToHighlight={[]}
                        attributesToRetrieve={['id', 'ItemExtrafieldsAnswers', 'sale_unit_code_translations', 'company_id', 'status_id', 'attachment_id', 'source_item_id', 'source_item_type_id', 'sale_unit_code', 'barcode', 'matchcode', 'capacity', 'capacity_unit_code', 'width', 'height', 'length', 'itemStocks', `itemPrices.${priceLineID}`, `itemDiscounts.${priceLineID}`, 'is_booklet', 'is_new', 'itemUnitBarcodeByUnitCodeBox', 'ratings', 'attachment', 'is_favorite', 'attachments', 'itemDescriptions', 'brand', 'country', `itemRecommendedPrices.${pricelineIDRecommended}`]}
                    />
                    <CustomInfiniteHits productView={productView} setClickFavorites={setClickFavorites} allProducts={allProducts} onProductClick={(id) => setProductId(id)} />
                        
                    <div className="left_filter" ref={leftFilterEl}>
                        <div className="left_filter__inner">
                            {filterNames.map((e, i) =>
                                <div className="filter_unit" key={`filterunit${i}`}>
                                    <div className="filter_unit__header">{filterTranslations[e]}</div>
                                    <button className="filter_unit__expand_collapse" onClick={ExpandCollapseFilterUnit}></button>
                                    <div className="filter_unit__search_wrapper">
                                        <input
                                            type="search"
                                            className="filter_unit__search"
                                            placeholder={t('niki.b2b.header.search')}
                                            onChange={FilterUnitSearch}
                                        />
                                    </div>
                                    <AlphabeticalRefinementList
                                        attribute={filterRefinement[e]}
                                        limit={1000}
                                        defaultRefinement={defaultFilterRefinement[e] ? [defaultFilterRefinement[e]] : null}
                                    />
                                </div>
                            )}
                        </div>
                        <button className="show_hide__left_filter" onClick={ShowHideLeftFilter}>
                            <span className="show_hide__left_filter__count">{selectedFilters}</span>
                            <span className="show_hide__left_filter__title">{showHideInnerText}</span>
                            <RaquoWhite ref={showHideFilterArrowEl} />
                        </button>
                    </div>
                </div>
            </InstantSearch>
            {productId && <ModalProductPage productID={productId} setProductId={setProductId} />}
        </>
    )
}