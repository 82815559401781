import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { t } from 'i18next'
import { xCompanyID, priceLineID, meilisearchUrl, companySupplier } from '../config'
import { PriceFormat, GetMainImageCart, TranslateJSON, GetHistoryData, FormatDate, getStockStatus, getTranslateStockStatus, getTranslatedName, LangFix, ShowLoader, HideLoader, storingCartData } from '../functions'

import { Breadcrumbs } from '../breadcrumbs'
import { InputRenderer } from '../components/myShoppingCart/InputRenderer'
import { TablePreloader } from '../components/TablePreloader'
import { AlertPopup } from '../components/AlertPopup'
import { ProdHistoryPopup } from '../components/ProdHistoryPopup'
import { ModalDeleteCart } from "../components/ModalDeleteCart"
import { PopupWithInput } from "../components/PopupWithInput"
import { CustomGroupRow } from "../components/myShoppingCart/CustomGroupRow"
import { CartAside } from "../components/myShoppingCart/CartAside"
import { getRequestOptions, updateProductInCart, guruRequestOptions, getCartsData, getRequestApi, getMeilisearchIndexName } from "../helpers/apiHelper"

import { deleteCartProduct, addCartProduct, clearCartContent, setCartHeaders, setDeleteProductID, setOpenDeleteProductAlert, setIsOpenMyShopingCart, setIsProductSearch, setShoppingCartSummaryCleare, setIdProductThatIsInCart } from '../reduxToolkit/cartSlice'
import { updateProduct } from '../reduxToolkit/productsSlice'
import { setIsOpenNoCartAlert, setIsMore5CartsAlert } from '../reduxToolkit/universalSlice'

import "../assets/css/cart.css"

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

import { AG_GRID_LOCALE } from '../aggridtranslations/locale.full'

import { ReactComponent as MoreVertical } from '../assets/svg/morevertical.svg'
import { ReactComponent as Refresh } from '../assets/svg/refresh.svg'
import { ReactComponent as ProdHistoryIcon } from '../assets/svg/prodhistory.svg'

import defaultImage from '../assets/images/placeholder.jpg'

import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey("CompanyName=SmartDigit, Lda,LicensedApplication=NIKI24,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-030570,SupportServicesEnd=27_August_2023_[v2]_MTY5MzA5MDgwMDAwMA==197101bde71f7a31a7dac0b3d3f0a6c8")

localStorage.setItem('cart.add.product','')
localStorage.setItem('cart.add.product.data','')

let ordersHistory = []
let ordersHistoryIDS = []
if (localStorage.getItem('orders.history') && localStorage.getItem('orders.history') !== 'undefined') {
    ordersHistory = JSON.parse(localStorage.getItem('orders.history'))
    ordersHistory.forEach(e => ordersHistoryIDS.push(e.source_item_id))
}

export const MyShoppingCart = () => {
    return (
        <>
            <div className="breadcrumbs_on_2_colummns">
                <Breadcrumbs itemlist={[{'url':'/myshoppingcart/','title':t('niki.b2b.cart.shoppingcart')}]} />
                <ol className="cart_steps">
                    <li className="current">{t('niki.b2b.cartflow.cart')}</li>
                    <li>{t('niki.b2b.cartflow.delivery')}</li>
                    <li>{t('niki.b2b.feedback.confirmation')}</li>
                </ol>
            </div>
            {/* {localStorage.getItem('cart.uuid') ? <MyShoppingCartContent /> : <MyShoppingCartNoContent /> */}
            <MyShoppingCartContent />
        </>
    )
}

const FetchProduct = async (codes) => {
    const filterInsert = `(barcode=${codes} OR source_item_id=${codes}) AND company_id=${xCompanyID}`
    const response = await fetch(`${meilisearchUrl}/indexes/${getMeilisearchIndexName("items")}/search?filter=${encodeURIComponent(filterInsert)}`, guruRequestOptions)
    const json = await response.json()
    return json
}

const requestCartDetailsID = (productID,productName,qty,price,tax) => {
    const URL = `${process.env.REACT_APP_API_DOMAIN}/cart-details?include=item,item.entityAccount`
    const body = JSON.stringify({
        cart_header_uuid: localStorage.getItem('cart.uuid'),
        item_id: productID,
        matchcode: productName,
        variation_id: '3',
        qty_in_box: qty.toString(),
        qty: qty.toString(),
        unit_price: price.toString(),
        tax_included_price: tax
    })
    const response = getRequestApi({ URL, method: 'POST', contentType: 'application/json', body })
    return response 
}

const returnSupplierId = (prod) => {
    return prod?.item?.data?.item_type_id
        ? prod?.item?.data?.item_type_id === 'k1ZKNp7jEnvlAy8D'
            ? 'k1ZKNp7jEnvlAy8D'
            : prod.item.data.item_type_id
        : ''
}

let counterProduct = 0
let selectProduct = 0

const MyShoppingCartContent = () => {
    const dispatch = useDispatch()

    const cartSumm = parseFloat(useSelector(state => state.cartContent.summ))
    const cartSummVat = parseFloat(useSelector(state => state.cartContent.vat))
    const cartNumProducts = useSelector(state => state.cartContent.count)
    const deleteProductID = useSelector(state => state.cartContent.deleteProductID)
    const isOpenDeleteAlert = useSelector(state => state.cartContent.isOpenDeleteProductAlert)

    const uuid = localStorage.getItem('cart.uuid')
    const localIdReal = localStorage.getItem('cart.id.real')
    const selectedLanguage = LangFix(localStorage.getItem('locale'))
    const shoppingCartExists = (localStorage.getItem('cart.uuid') && localStorage.getItem('cart.id')) ? true : false

    const [cartID, setCartID] = useState('')
    const [dataLoaded, setDataLoaded] = useState(false)
    const [cartIDReal, setCartIDReal] = useState('')
    const [cartName, setCartName] = useState('')
    const [createdAt, setCreatedAt] = useState('')
    const [cartColumns, setCartColumns] = useState('two_columns')
    const [deleteRowIndex, setDeleteRowIndex] = useState(null)
    const [isOpenAlert, setOpenAlert] = useState(false)
    const [dataDeleteProduct, setDeleteProduct] = useState({cartDetail:'', productid:''})
    const [updateFromQuickOrder, setUpdateFromQuickOrder] = useState(false)
    const [supplierTypes, setSupplierTypes] = useState([])
    const [isOpenDropshipping, setIsOpenDropshipping] = useState(false)
    const [newCartName, setNewCartName] = useState('')
    const [isOpenProductNotFoundAlert, setIsOpenProductNotFoundAlert] = useState(false)
    const [isOpenDeleteCartAlert, setIsOpenDeleteCartAlert] = useState(false)
    const [errorText, setErrorText] = useState('')

    const deleteCartAlertRef = useRef(null)
    const gridRef = useRef()
    const searchProductEl = useRef(null)
    const searchProductSubmitEl = useRef(null)
    const moreMenuEl = useRef(null)
    const moreMenuOverlayEl = useRef(null)
    const cartRenameWrapperEl = useRef(null)
    const refEnterCartName = useRef(null)

    useEffect(() => {
        dispatch(setIsOpenMyShopingCart(true))
        return () => dispatch(setIsOpenMyShopingCart(false))
        // eslint-disable-next-line
    }, [])

    const groupRowRendererParams = useMemo(() => {
        return {
          innerRenderer: CustomGroupRow,
          suppressCount: true,
        };
      }, []);

    useEffect(() => {
        setCartIDReal(localIdReal)
    }, [localIdReal])

    const localeText = useMemo(() => {
        return TranslateJSON(AG_GRID_LOCALE)
    }, [])

    const gridStyles = {
        color: 'var(--darkgrey)',
        fontSize: '16px'
    }
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        }
    }, [])

    const RemoveCartItem = (cartDetail, productId) => {
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details/${cartDetail}`, getRequestOptions('DELETE'))
            .then(response => {
                dispatch(deleteCartProduct(cartDetail))
                dispatch(updateProduct({ id: productId, qty: 0 }))
                setRowData(prevState => prevState.filter(p => p.product_id !== productId))
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    }

    /* HistoryProduct */
    const [openHistoryProduct, setOpenHistoryProduct] = useState(false)
    const [idHistoryProduct, setIdHistoryProduct] = useState('')
    const [productData, setProductData] = useState({})

    useEffect(() => {
        if (openHistoryProduct) {
            const PopupSelector = `.product_history__wrapper_${idHistoryProduct}`
            document.querySelector(PopupSelector).classList.add('active')
        }      
    }, [openHistoryProduct, idHistoryProduct])
    
    const ShowProdHistory = e => {
        setOpenHistoryProduct(true)
        setIdHistoryProduct(e.target.dataset.id)
        setProductData(JSON.parse(e.target.dataset.data))
    }  
    /* HistoryProduct */

    const columnDefsWork = [
        {
            headerName: '',
            field: 'image',
            width: 90,
            floatingFilter: false,
            checkboxSelection: true,
            pinned: 'left',
            cellRenderer: params => <NavLink to={`/productpage/${params.data.product_id}/`} onClick={() => localStorage.setItem('url.back','/myshoppingcart/')} className="grid_prod_img"><img src={params.data.image} alt={params.data.name} /></NavLink>
        },
        {
            headerName: 'ID',
            width: 120,
            field: 'item_id',
            pinned: 'left',
        },
        {
            field: 'name',
            headerName: t('niki.b2b.cartflow.product'),
            width: 300,
            autoHeight: true,
            filter: 'agTextColumnFilter',
            cellRenderer: params => {
                const prodHistory = GetHistoryData(params.data.product_id)
                return (
                    <>
                        <strong className="ag_prodname">{params.data.name.slice(0, params.data.name.indexOf('('))}</strong>
                        {/* {params.data.item_id} */}
                        {prodHistory.length > 0
                            &&
                            <button
                                className="ag_prodhistory"
                                onClick={ShowProdHistory}
                                data-id={params.data.item_id}
                                data-data={JSON.stringify(params.data)}
                            ><Refresh className="ag_prodhistory__refresh" />{prodHistory.filter((_, index) => index === 0).map((e, i) =>
                                <span key={`historyel${params.data.id}${i}`}>{FormatDate(e.created_at)} &mdash; {e.quantity} {t('niki.b2b.returnproducts.units')}</span>
                            )}<ProdHistoryIcon className="ag_prodhistory__list" /></button>
                        }
                    </>
                )
            },
        },
        {
            field: 'group',
            headerName: t('niki.b2b.shoppingcart.group'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'type',
            headerName: t('niki.b2b.feedback.type'),
            width: 100,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'qty',
            headerName: t('niki.b2b.feedback.qty'),
            filter: false,            
            aggFunc: (params) => {
                let sum = 0;
                params.values.forEach((value) => (sum += value));
                return sum;
            },
            cellRenderer:  InputRenderer,
        },
        {
            field: 'UNIcode',
            headerName: t('niki.field.sale_unit_id'),
            width: 130,
            enableRowGroup: true,
            filter: false,
        },
        {
            field: 'supplier',
            headerName: t('niki.b2b.feedback.supplier'),
            enableRowGroup: true,
            rowGroup: isOpenDropshipping && supplierTypes.filter(i => i !== undefined).length  > 1,
            hide: isOpenDropshipping && supplierTypes.filter(i => i !== undefined).length > 1,
        },
        {
            field: 'translateStock',
            headerName: t('niki.b2b.feedback.status'),
            width: 100,
            filter: false,
            cellRenderer: params => {
                return <div className="aggrid_status__wrapper"><div className={['aggrid_status', params.data.instock].join(' ')}></div></div>
            },
            enableRowGroup: true,
        },
        {
            field: '',
            headerName: t('niki.b2b.allproducts.discounts'),
            width: 100,
            filter: false,
            cellRenderer: params => {
                const price = params.data.price
                const priceOld = params.data.priceOld
                if (priceOld > 0 && price !== priceOld) {
                    return <div className="aggrid_discount">{Math.round((priceOld - price) / priceOld * 100) < 0 ? '' : '-'}{Math.round((priceOld - price) / priceOld * 100)}%</div>
                } else {
                    return null
                }
            }
        },
        {
            field: 'price',
            headerName: t('niki.b2b.cart.unipr'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => `${PriceFormat(params.value)}`,
        },
        {
            field: 'total_net',
            headerName: t('niki.b2b.orders.totalnet'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            cellRenderer: params => `${PriceFormat(params.value)}`,
        },
        {
            field: 'total',
            headerName: t('niki.b2b.cart.total'),
            filter: 'agTextColumnFilter',
            width: 0,
            minWidth: 0,
            cellClass: 'custom_style_cell__not_show',
            aggFunc: (params) => {
                let sum = 0;
                const valuta = PriceFormat(params.values[0]) && PriceFormat(params.values[0]).slice(0,1)
                params.values.forEach((value) => (sum += +PriceFormat(value).replace(/[^0-9.]/g, "")));
                return `${valuta}${sum}`;
            },
        },
        {
            field: 'estimated_delivery',
            filter: 'agTextColumnFilter',
            width: 0,
            minWidth: 0,
            cellClass: 'custom_style_cell__not_show',
            aggFunc: (params) => {
                return params.values[0];
            },
        },
        {
            field: 'vat',
            headerName: t('niki.b2b.product.vat'),
            filter: 'agTextColumnFilter',
            width: 120,
            cellClass: 'inrightside',
            // cellRenderer: params => `${PriceFormat(params.value)}`
            cellRenderer: params => params.data.vat > 0 ? `${params.value}%` : null
        },
    ]

    const onCellValueChanged = useCallback((event) => {
        const cartDetail = event.data.id
        const localValue = event.data.qty
        updateProductInCart(localValue, cartDetail)
    }, [])

    const [rowData, setRowData] = useState([])

    const [prodActions, SetProdActions] = useState('none')
    const [selectedProducts, setSelectedProducts] = useState(0)
    const onSelectionChanged = useCallback((event) => {
        var selectedRows = gridRef.current.api.getSelectedRows()
        setSelectedProducts(selectedRows.length)
    }, [])

    const ApplyClick = useCallback(() => {
        if (prodActions === 'none') {
            gridRef.current.api.deselectAll()
        } else {
            const selectedData = gridRef.current.api.getSelectedRows()
            if (!!selectedData && selectedData?.length > 0) {
                selectedData.forEach(e => {
                    RemoveCartItem(e.id, e.product_id)
                    setRowData(prevState => prevState.filter(p => p.product_id !== e.product_id))
                })
                if (selectedData?.length === 1) { 
                    const findProduct = gridRef.current.api.getRenderedNodes().filter(p => p.data.product_id === selectedData[0].product_id)[0]
                    setDeleteRowIndex(findProduct.rowIndex)
                }
                gridRef.current.api.applyTransaction({ remove: selectedData })
            }
        }
        // eslint-disable-next-line
    }, [prodActions])
    
    useEffect(() => {
        const resSet = new Set()
        rowData.forEach(i => resSet.add(i.idForGruppingSupplier))
        resSet.size === 1 && setSupplierTypes([...resSet])
    }, [rowData])

    useEffect(() => {
        if (deleteProductID) {
            setRowData(prevState => prevState.filter(p => p.id !== deleteProductID))
            dispatch(setDeleteProductID(''))
        }
        // eslint-disable-next-line
    }, [deleteProductID])

    useEffect(() => {
        if (rowData.length !== counterProduct) {
            if (counterProduct - rowData.length === 1 && deleteRowIndex !== null) {
                dispatch(setShoppingCartSummaryCleare())
                dispatch(setIsProductSearch(true))
                gridRef.current.api.ensureIndexVisible(deleteRowIndex-1, 'middle')
            }
            counterProduct = rowData.length
        }
         // eslint-disable-next-line
    }, [rowData, deleteRowIndex])

    useEffect(()=>{
        if (shoppingCartExists) {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-header/${uuid}/${localStorage.getItem('account.entity.id')}?realKey=1`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                setCartID(json.data.id)
                setCartIDReal(json.data.id_real)
                setCreatedAt(json.data.readable_created_at)
                setCartName(json.data.matchcode)
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
    
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-details?cart_header_uuid=${uuid}&include=item,item.itemCategories,item.entityAccount,item.itemDescriptions,item.attachments,item.itemDiscounts,item.itemPrices,item.itemKinds,item.itemStocks,item.itemFamily&source_price_id[]=${priceLineID}&warehouse_id=${localStorage.getItem('warehouse.id')}&showVat=true&limit=1000&rand=${Math.random()}`,getRequestOptions())
            .then(response => response.json())
            .then(json => {
                const checkSupplierTypes = new Set()
                setRowData([])
                json.data.forEach(e => {
                    const itemProduct = e.item?.data?.itemPrices?.data?.length ? e.item.data.itemPrices.data.filter(i => i.priceline_id === priceLineID)[0] : {unit_price: 0, tax_included_price: 0}
                    let price = itemProduct.unit_price
                    let priceOld = 0
                    let vat = parseFloat(itemProduct.tax_included_price) - parseFloat(itemProduct.unit_price)

                    if (e?.item?.data?.itemDiscounts?.data?.length) {
                        priceOld = price
                        const itemProductDiscounts = e.item.data.itemDiscounts.data.filter(i => i.priceline_id === priceLineID)[0] || {unit_price: 0, tax_included_price: 0}
                        price = parseFloat(itemProductDiscounts.unit_price)
                        vat = parseFloat(itemProductDiscounts.tax_included_price) - parseFloat(itemProductDiscounts.unit_price)
                        // vat = ((parseFloat(itemProductDiscounts.tax_included_price) - parseFloat(itemProductDiscounts.unit_price)) * e.qty) || 0
                    }
                    e.item?.data?.item_type_id && checkSupplierTypes.add(e.item.data.item_type_id)
                    const idForGruppingSupplier = returnSupplierId(e)
                    const prodImage = (e.item && e.item.data.attachments && GetMainImageCart(e.item.data.attachments,e.item.data.attachment_id)) || defaultImage

                    const tempObj = {
                        id: e.id,
                        product_id: e.product_id,
                        item_id: e.item.data.item_id,
                        name: getTranslatedName(e.item.data) ? getTranslatedName(e.item.data) : e.matchcode,
                        qty: e.qty,
                        qty_in_box: e.qty_in_box,
                        price: price,
                        priceOld: priceOld,
                        total: parseFloat(e.qty * (price ? price : e.unit_price) + vat).toFixed(2),
                        total_net: parseFloat(e.qty * (price ? price : e.unit_price)).toFixed(2),
                        group: e?.item?.data?.itemFamily?.data?.description || '',
                        type:  e?.item?.data?.itemKinds?.data[0]?.name || '',
                        image: prodImage,
                        translateStock: getTranslateStockStatus(e?.item?.data),
                        instock: getStockStatus(e),
                        vat: Math.round(vat / price * 100),
                        UNIcode: e.UNI,
                        sale_unit_code: e.item?.data?.sale_unit_code,
                        idForGruppingSupplier,
                        entity_account_id: e?.item?.data?.entityAccount?.data?.id || '',
                        estimated_delivery: e?.item?.data?.entityAccount?.data?.estimated_delivery ? e.item.data.entityAccount.data.estimated_delivery : null,
                        supplier: e?.item?.data?.item_type_id !== '5QrvVGorkVn14mAR' && e?.item?.data?.item_type_id !== 'rm0GNla0Q6og35Me'? companySupplier : `${e.item?.data?.entityAccount?.data?.external_id || ''}`,
                        setDeleteRowIndex: setDeleteRowIndex,
                        setDeleteProduct: setDeleteProduct,
                    }
                    setRowData(prevState => ([...prevState, tempObj])) 
                })
                setSupplierTypes(prev => [...prev, ...checkSupplierTypes])
                setDataLoaded(true)
            })
            .catch(error => console.error(error))
        }

    }, [uuid, shoppingCartExists])

    useEffect(() => {
        if (rowData.length === cartNumProducts) {
            setUpdateFromQuickOrder(false)
        } else if (!updateFromQuickOrder && document.querySelector('.quick_order__wrapper') && (rowData.length !== cartNumProducts)) {
            setUpdateFromQuickOrder(true)
        } 
    }, [cartNumProducts, rowData, updateFromQuickOrder])

    const DisableSearchForm = () => {
        searchProductSubmitEl.current.disabled = true
        searchProductEl.current.disabled = true
        searchProductEl.current.classList.add('disabled')
    }
    const EnableSearchForm = () => {
        searchProductSubmitEl.current.disabled = false
        searchProductEl.current.disabled = false
        searchProductEl.current.classList.remove('disabled')
        searchProductEl.current.value = ''
        localStorage.setItem('cart.add.product','')
        localStorage.setItem('cart.add.product.data','')
    }
    const [searchProduct, setSearchProduct] = useState('')
    const [idProductThatIsCart, setIdProductThatIsCart] = useState('')

    const returnError = ( message) => { 
        if (localStorage.getItem('cart.uuid')) { 
            getCartsData()
                .then(json => {
                    let existCart = false 
                    json.data && json.data.forEach(e => {
                        existCart = e.uuid === localStorage.getItem('cart.uuid') && true
                    })
                    existCart 
                        ? setErrorText(t('niki.b2b.error_adding_product_cart') +', ' + message)
                        : dispatch(setIsOpenNoCartAlert(true))
                })
                .catch(error => {
                    setErrorText(t('niki.b2b.error_adding_product_cart') +', ' + message)
                    console.error(error)
                })   
        } else {
            setErrorText(t('niki.b2b.error_adding_product_cart') +', ' + message)
        }
    }
    
    const SearchProductForm = e => {
        e.preventDefault()
        DisableSearchForm()
        ShowLoader()

        FetchProduct(searchProduct)
        .then(json => {
            if (json.hits?.length > 0) { 
                const product = json.hits[0] 
                
                if (gridRef.current?.props?.rowData?.filter(p => p.product_id === product.id).length === 1) {
                    setIdProductThatIsCart(product.id)
                    setOpenAlert(true)
                    EnableSearchForm()
                    if (gridRef.current) {
                        const rowNode = gridRef.current.api.getRowNode(selectProduct);
                        rowNode.selectThisNode(false);
                        const findProduct = gridRef.current.props.rowData.map((p, i) => {
                            if (p.product_id === product.id) {
                                return { ...p, rowIndex: i }
                            } else { 
                                return undefined
                            }
                        }).filter(p => p !== undefined)[0]
                        gridRef.current.api.ensureIndexVisible(findProduct.rowIndex, 'middle');
                        selectProduct = findProduct.rowIndex
                        let node = gridRef.current.api.getDisplayedRowAtIndex(findProduct.rowIndex);
                        node.setSelected(true)
                    }
                } else {
                    const checkSupplierTypes = new Set()
                    supplierTypes.forEach(p => checkSupplierTypes.add(p))

                    const itemPrices = product?.itemPrices ? product?.itemPrices[priceLineID] : 0
                    const discounts = product.is_discount ? product?.itemDiscounts[priceLineID] : 0 
                    const unitPrice = parseFloat(itemPrices.unit_price) || 0
                    const taxIncludedPrice = parseFloat(itemPrices.tax_included_price) || 0

                    const prodItemID = product.source_item_id
                    const prodName = product?.itemDescriptions[selectedLanguage]?.name ? product.itemDescriptions[selectedLanguage].name : product.matchcode
                    const prodPrice = product.is_discount ? parseFloat(discounts.unit_price) : unitPrice
                    const prodPriceOld = unitPrice
                    const prodTax = product.is_discount ? parseFloat(discounts.tax_included_price) : taxIncludedPrice
                    const prodTaxOld = product.is_discount ? taxIncludedPrice : 0
                    const prodQty = parseInt(product?.itemUnitBarcodeByUnitCodeBox?.coefficient) || 0
                    const prodImage = product.attachment || defaultImage
                    const prodGroup = product.family || ''
                    const prodType = (product?.itemKinds?.data[0]?.name) || ''
                    const vat =  ((prodPriceOld - prodPrice)) || 0

                    let cartdata = {                
                        name: prodName,
                        id: product.id,
                        itemid: product.source_item_id,
                        productid: product.id,
                        productitemid: product.barcode,
                        qty: prodQty,
                        qtyinbox: prodQty,
                        unitcode: product.sale_unit_code || '',
                        price: prodPrice,
                        priceold: prodPriceOld,
                        vat: vat,
                        sale_unit_code: product.sale_unit_code,
                        image: prodImage,

                        tax: prodTax,
                        //total: (prodQty * prodPrice).toFixed(2),
                    }
                    const tempObj = {
                        id: product.id,
                        product_id: product.id,
                        item_id: prodItemID,
                        name: prodName,
                        qty: prodQty,
                        qty_in_box: prodQty,
                        price: prodPrice,
                        // price_discount: prodPriceOld-prodPrice,
                        tax: prodTax,
                        tax_old: prodTaxOld,
                        group: prodGroup,
                        type: prodType,
                        image: prodImage,
                        total: (prodQty * prodPrice + vat).toFixed(2),
                        total_net: (prodQty * prodPrice).toFixed(2),
                        instock: product.itemStocks[0]?.available > 0 ? product.itemStocks[0]?.available > 20 ? 'instock' : 'low' : 'outofstock',
                        translateStock: product.itemStocks[0]?.available > 0 ? product.itemStocks[0]?.available > 20 ? t('niki.b2b.instock') : t('niki.b2b.low') : t('niki.b2b.outofstock'),
                        vat: vat,
                        sale_unit_code: product.sale_unit_code,
                        supplier: product.source_item_type_id === 0 ? companySupplier : product.source_item_type_id,
                        priceOld: prodPriceOld,
                        setDeleteRowIndex: setDeleteRowIndex,
                        setDeleteProduct: setDeleteProduct,
                    }
                   
                    localStorage.setItem('cart.add.product',JSON.stringify({id: product.id, name: prodName, price: prodPrice, priceold: prodPriceOld, qty: prodQty, item_id: prodItemID, tax: prodTax, tax_old: prodTaxOld, vat:((taxIncludedPrice - prodPrice)*prodQty) || 0,}))
                    localStorage.setItem('cart.add.product.data', JSON.stringify(cartdata))
                   
                    if (localStorage.getItem('cart.uuid') && gridRef?.current?.props?.rowData) {
                        const cartAddData = JSON.parse(localStorage.getItem('cart.add.product'))
                        dispatch(setIsProductSearch(true))
                        isOpenDropshipping && dispatch(setShoppingCartSummaryCleare())
                        ShowLoader()
                        requestCartDetailsID(cartAddData.id, cartAddData.name, cartAddData.qty, cartAddData.price, cartAddData.tax)
                            .then(response => response.json())
                            .then(json => {
                                const cartData = json.data
                                if (json.message) {
                                    setRowData(prevState => (prevState.filter(p => p.id !== product.id)))
                                    json.message.includes("authenticate") ? setErrorText(t('niki.b2b.alert.productnotfound')) : returnError(json.message)
                                } else if (cartData) {
                                    setRowData(prevState => ([...prevState, tempObj]))

                                    const entity_account_id = cartData?.item?.data?.entityAccount?.data?.id || ''
                                    const estimatedDelivery = cartData?.item?.data?.entityAccount?.data?.estimated_delivery || null
                                    const newData = gridRef?.current?.props?.rowData[gridRef?.current?.props?.rowData?.length - 1]
                                    newData.id = cartData.id
                                    newData.UNIcode = cartData.UNI
                                    newData.estimated_delivery = estimatedDelivery
                                    newData.entity_account_id = entity_account_id
                                    newData.idForGruppingSupplier = returnSupplierId(cartData)
                                    newData.supplier = cartData?.item?.data?.item_type_id === 'k1ZKNp7jEnvlAy8D' ? companySupplier : `${cartData.item.data.entityAccount.data.external_id}`
                                        
                                    gridRef.current.api.applyTransaction({ update: [newData] })
                                    
                                    const newObj = localStorage.getItem('cart.add.product.data') ? JSON.parse(localStorage.getItem('cart.add.product.data')) : cartdata
                                    newObj.qty = newData.qty
                                    newObj.id = cartData.id
                                    newObj.UNIcode = cartData.UNI
                                    newObj.entity_account_id = entity_account_id
                                    newObj.estimated_delivery = estimatedDelivery
                                    dispatch(addCartProduct(newObj))

                                    localStorage.setItem('cart.add.product.data', JSON.stringify(newObj))
                                    EnableSearchForm()
                                    cartData.item?.data?.item_type_id && checkSupplierTypes.add(cartData.item.data.item_type_id)
                                    setSupplierTypes([...checkSupplierTypes])
                                    setSearchProduct('')
                                    if (gridRef.current) {
                                        const rowNode = gridRef.current.api.getRowNode(selectProduct);
                                        rowNode && rowNode.selectThisNode(false);
                                        const lengthRow = gridRef.current?.props?.rowData?.length
                                        gridRef.current.api.ensureIndexVisible(lengthRow-1, 'middle')
                                    }
                                }
                            })
                            .catch(error => {
                                setSearchProduct('')
                                console.error(error)
                            })
                            .finally(() => {
                                HideLoader()
                                searchProductEl.current.focus()
                                setSelectedProducts(0)
                            })
                    }
                    gridRef.current.api.forEachNode(function (node) {
                        node.setExpanded(node.lastChild === true)
                    })

                    if (gridRef.current) {
                        const lastRowIndex = gridRef.current.api.getDisplayedRowCount() - 1;
                        gridRef.current.api.ensureIndexVisible(lastRowIndex, 'bottom');
                    }
                }
            } else {
                EnableSearchForm()
                setIsOpenProductNotFoundAlert(true)
            }
        })
        .catch(error => {
            
            console.error(error)
        })
        .finally(() => {
            EnableSearchForm()
            HideLoader()
        })
    }

    const CreateNewCart = (e) => {
        e.preventDefault()
        const requestOptionsCart = getRequestOptions('POST', null, 'application/json')
        requestOptionsCart.body = JSON.stringify({
            company_id: localStorage.getItem('account.company.hashed'),
            customer_account_id: localStorage.getItem('account.entity.id'),
            status_id: 'J8RvMwowm7k2pNOr',
            realKey: '1',
            matchcode: newCartName,
        })
        
        ShowLoader()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-headers`,requestOptionsCart)
        .then(response => response.json())
            .then(json => {
                if (json.message && json.errors) {
                    closePopupEnterCartName()
                    dispatch(setIsMore5CartsAlert(true)) 
                }
                if (json.data) {
                    setCartName(newCartName)
                    setCartID(json.data.id)
                    setCartIDReal(json.data.id_real)
                    setCreatedAt(json.data.readable_created_at)
                    setRowData([])
                    localStorage.setItem('create_new_cart', JSON.stringify(json.data))
                    storingCartData(json.data.uuid, json.data.real_id, json.data.id)
                    dispatch(setCartHeaders({
                        id: json.data.id,
                        idreal: json.data.real_id,
                        name: json.data.matchcode || ''
                    }))
                    CloseMoreMenu()
                    setNewCartName('')
                    closePopupEnterCartName()
                }
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        dispatch(clearCartContent())
    }

    const ShowMoreMenu = () => {
        moreMenuEl.current.classList.add('active')
        moreMenuOverlayEl.current.classList.add('active')
    }
    const CloseMoreMenu = () =>{
        moreMenuEl.current.classList.remove('active')
        moreMenuOverlayEl.current.classList.remove('active')
    }
    const ShowRenamePopup = () => {
        CloseMoreMenu()
        cartRenameWrapperEl.current.classList.add('active')
    }
    useEffect(() => {
        let timer;

        if (isOpenDeleteCartAlert) {
            clearTimeout(timer);
            timer = setTimeout(() => deleteCartAlertRef.current.classList.add('active'), 400)
        } 
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [isOpenDeleteCartAlert])

    const DeleteCart = () => {
        setIsOpenDeleteCartAlert(true)
        CloseMoreMenu()
    }
    const CloseRenamePopup = () => cartRenameWrapperEl.current.classList.remove('active')
    const ClosePopup = () =>  setIsOpenDeleteCartAlert(false)
    
    const ConfirmDelete = (deleteID) => {
        dispatch(clearCartContent())
        window.location.href = '/shoppingcarts/'
    }

    const [newName,setNewName] = useState('')
    const SubmitNewName = e => {
        e.preventDefault()
        const requestOptionsPatchMatchcode = getRequestOptions('PATCH', null, 'application/json')
        requestOptionsPatchMatchcode.body = JSON.stringify({matchcode: newName})
        
        try {
            ShowLoader()
            fetch(`${process.env.REACT_APP_API_DOMAIN}/cart-header/${localStorage.getItem('cart.uuid')}/${localStorage.getItem('account.entity.id')}`,requestOptionsPatchMatchcode)
            .then(response => {
                if (response.ok) {
                    dispatch(setCartHeaders({ id: cartID, idreal: cartIDReal, name: newName }))
                    setCartName(newName)
                    CloseRenamePopup()
                }
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }

    const ChangeColumnsView = () => cartColumns === 'two_columns' ? setCartColumns('one_column') : setCartColumns('two_columns')
    
    const closeAlert = () => {
        if (isOpenDeleteAlert) {
            dispatch(setOpenDeleteProductAlert(false))
        } else {
            if (isOpenProductNotFoundAlert) {
                searchProductEl.current.focus()
                setIsOpenProductNotFoundAlert(false)
            } else {
                searchProductEl.current.focus()
                setOpenAlert(false)
                setSearchProduct('')
            }
        }      
    }

    const onUpdateProductQuantity = () => {
        dispatch(setIdProductThatIsInCart(idProductThatIsCart))
        searchProductEl.current.focus()
        setOpenAlert(false)
        setSearchProduct('')
    }

    const onDeleteFromAlert = () => {
        dispatch(setOpenDeleteProductAlert(false))
        RemoveCartItem(dataDeleteProduct.cartDetail, dataDeleteProduct.productid)
        let indexItem = 0
        rowData.forEach((p, i) => {
            if (p.product_id === dataDeleteProduct.productid) { indexItem = i }
        })
        setRowData(prevState => prevState.filter(p => p.product_id !== dataDeleteProduct.productid))
        if (gridRef.current) {
            gridRef.current.api.ensureIndexVisible(indexItem)
        }
    }

    const openPopupEnterCartName = () => {
        refEnterCartName.current.classList.add('active')
        CloseMoreMenu()
    }

    const closePopupEnterCartName = () => refEnterCartName.current.classList.remove('active')

    return (
        <>
            <div className="wrapper">
                <h1>{(cartName && cartName.length > 0) ? `${cartIDReal} ${cartName}` : `${t('niki.b2b.cart.shoppingcart')} ${cartIDReal ? cartIDReal : ''}`}
                    <span className="more_button">
                        <button onClick={ShowMoreMenu}><MoreVertical /></button>
                        <div className="more_menu" ref={moreMenuEl}>
                            <button onClick={openPopupEnterCartName}>{t('niki.b2b.cartflow.createnewcart')}</button>
                            <button onClick={ShowRenamePopup}>{t('niki.b2b.cartflow.rename')}</button>
                            <button onClick={DeleteCart} data-id={localStorage.getItem('cart.id')}>{t('niki.b2b.shoppingcart.deletecart')}</button>
                        </div>
                    </span>
                </h1>
                {shoppingCartExists && <div className="myshoppingcart__created">{t('niki.b2b.cart.created')}: <span>{createdAt}</span></div>}
                <div className={[`cart_columns`,cartColumns].join(' ')}>
                    <div className="cart_content">
                        {dataLoaded
                            ? <>
                                <div className="ag-theme-alpine" style={{height: 700, width: '100%'}}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={rowData}
                                        columnDefs={columnDefsWork}
                                        defaultColDef={defaultColDef}
                                        rowHeight="76"
                                        rowStyle={gridStyles}
                                        rowGroupPanelShow={'always'}
                                        rowGroup={'true'}
                                        animateRows={true}
                                        singleClickEdit={true}
                                        onCellValueChanged={onCellValueChanged}
                                        onSelectionChanged={onSelectionChanged}
                                        rowSelection={'multiple'}
                                        masterDetail={true}
                                        detailRowHeight={75}
                                        groupDisplayType={'groupRows'}
                                        localeText={localeText}
                                        groupRowRendererParams={groupRowRendererParams}
                                        suppressDragLeaveHidesColumns={true}
                                    >
                                    </AgGridReact>
                                </div>    
                                <div className="cart_product__actions">
                                    <div className="cart_product__actions__label">{selectedProducts} {t('niki.b2b.cartflow.productsselected')}</div>
                                    <select className="form-control" onChange={e => SetProdActions(e.target.value)}>
                                        <option value="none">{t('niki.b2b.cartflow.bulkactions')}</option>
                                        <option value="delete">{t('niki.b2b.cartflow.deleteproducts')}</option>
                                    </select>
                                    <button className="btn btn-info" onClick={ApplyClick}>{t('niki.b2b.cartflow.apply')}</button>
                                </div>
                                <div className="add_product_to_cart">
                                    <button className="plus_product">+</button>
                                    <form className="add_product_to_cart__wrapper" onSubmit={SearchProductForm}>
                                        <input
                                            type="search"
                                            className="form-control form-control-little nomagnify search_product_input"
                                            placeholder={t('niki.b2b.cartflow.productsku')}
                                            onChange={e => setSearchProduct(e.target.value)}
                                            value={searchProduct}
                                            ref={searchProductEl}
                                            required
                                            disabled={false}
                                        />
                                        <button type="submit" className="add_product_to_cart__submit" ref={searchProductSubmitEl}></button>
                                    </form>
                                </div>
                                {openHistoryProduct && 
                                    <ProdHistoryPopup
                                        matchcode={productData.name}
                                        unit_code={productData.UNIcode}
                                        qty_in_box={productData.qty_in_box}
                                        item_id={productData.item_id}
                                        imgsrc={productData.image}
                                        setOpenHistoryProduct={setOpenHistoryProduct}
                                        product_id={productData.product_id}
                                    />
                                }
                            </>
                            : <TablePreloader />
                        }
                    </div>
                    <CartAside supplierTypes={supplierTypes} isOpenDropshipping={isOpenDropshipping} setIsOpenDropshipping={setIsOpenDropshipping} />
                    <button className="change_columns_view" type="button" onClick={ChangeColumnsView}></button>
                </div>
            </div>
            <div className="more_menu__overlay" onClick={CloseMoreMenu} ref={moreMenuOverlayEl}></div>
            <PopupWithInput refWrapper={cartRenameWrapperEl} onSubmit={SubmitNewName} setNewValue={setNewName}
                close={CloseRenamePopup} title='niki.b2b.cartflow.rename' titleSubmitButton='niki.b2b.cartflow.renamecart'
            />
            {isOpenDeleteCartAlert && <ModalDeleteCart refEl={deleteCartAlertRef} ClosePopup={ClosePopup} cartIDReal={cartIDReal} skus={rowData.length} gros={cartSumm + cartSummVat}
                cartID={cartID} ConfirmDelete={ConfirmDelete} 
            />}
            <PopupWithInput refWrapper={refEnterCartName} onSubmit={CreateNewCart} setNewValue={setNewCartName}
                close={closePopupEnterCartName} title='niki.b2b.cart.enter_cart_name' titleSubmitButton='niki.b2b.cart.create'
            />
            {isOpenAlert &&
                <AlertPopup
                    text={`${t('niki.b2b.cartflow.item_has_in_cart')}. ${t('niki.b2b.can_ignore_or_add_box')}.`}
                    closeAlert={closeAlert}
                    titleFirstButton={'niki.b2b.cartflow.addtocart'}
                    towButtons={true}
                    onFirstButtonClick={onUpdateProductQuantity}
                />
            }
            {isOpenProductNotFoundAlert && <AlertPopup text={t('niki.b2b.alert.productnotfound')} closeAlert={closeAlert} />}
            {isOpenDeleteAlert && <AlertPopup text={t('niki.b2b.you_want_remove_product')} closeAlert={closeAlert} towButtons={true} onFirstButtonClick={onDeleteFromAlert}/>}
            {errorText && <AlertPopup text={errorText} closeAlert={() => setErrorText('')} />}
        </>
    )
}