import React, { useEffect, useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { t } from "i18next"

import { xCompanyID, companyName, languages, languagesArray, xTenant, siteDomain} from './config'
import { FixCompanyName, FetchPreferencesPost, ShowLoader, HideLoader } from './functions'

import './assets/css/footer.css'

import { ReactComponent as Facebook } from './assets/svg/facebook.svg'
import { ReactComponent as Linkedin } from './assets/svg/linkedin.svg'
import { ReactComponent as Instagram } from './assets/svg/instagram.svg'
import { ReactComponent as Youtube } from './assets/svg/youtube.svg'
import { ReactComponent as TikTok } from './assets/svg/tiktok.svg'
import { ReactComponent as Globe } from './assets/svg/globe.svg'

const FooterLang = () => {
    const LangSelectorEl = useRef(null)
    const [selectedLang, setLang] = useState({
        flag: localStorage.getItem('locale') || 'gb',
        country: languagesArray[localStorage.getItem('locale')] || 'English'
    })
    const [isOpen,setIsOpen] = useState(false)

    const SelectLang = e => {
        if (e.target.dataset.flag === localStorage.getItem('locale')) {
            LangSelectorEl.current.classList.remove('active')
        } else {
            LangSelectorEl.current.classList.remove('active')
            localStorage.setItem('locale',e.target.dataset.flag)
            localStorage.setItem('locale.full',e.target.dataset.country)
            ShowLoader()
            FetchPreferencesPost(e.target.dataset.flag)
            .then(json => {
                setLang({
                    flag: e.target.dataset.flag,
                    country: languagesArray[e.target.dataset.flag],
                })
                document.location.reload()
            })
            .catch(error => console.error(error))
            .finally(() => HideLoader())
        }
    }
    function closeModalOnClickOutside(event) {
        const modal = document.getElementById('lang_table')
        const lang = document.getElementById('lang')
        if (event.target === lang) {

        } else if (event.target !== modal) {
        LangSelectorEl.current.classList.remove('active')
        }
    }
    useEffect(() => {
        isOpen && document.addEventListener('click', closeModalOnClickOutside)
        return () => {
            document.removeEventListener('click', closeModalOnClickOutside);
        };
    }, [isOpen])
    
    
    const Langs = languages.map((item,key) => (
        <li key={key} className={'lang_'+item.flag} data-flag={item.flag} data-country={item.country} data-translateCountry={item.translateCountry} onClick={SelectLang}>
            <span className={'login_form__flag flag-icon-squared flag-icon-'+item.flag}></span>
            {t(item.translateCountry)}
        </li>
    ))
    const explandCollapseLangs = () => {
        LangSelectorEl.current.classList.toggle('active')
        setIsOpen(true)
    }
    return (
        <div className="login_form__lang_selector">
            <div className="login_form__lang_selector__selected" onClick={explandCollapseLangs} id='lang'>
                <span className={`login_form__flag flag-icon-${selectedLang.flag}`}></span>
                {t(selectedLang.country)}
            </div>
            <ul className="login_form__lang_selector__wrapper" ref={LangSelectorEl} id='lang_table'>{Langs}</ul>
        </div>
    )
}

export const Footer = () => {
    const { t } = useTranslation()
    const socials = (localStorage.getItem('socials') && localStorage.getItem('socials') !== 'undefined') ? JSON.parse(localStorage.getItem('socials')) : []

    const returnSocialIcon = (icon) => {
        switch (icon) {
            case "Facebook":
                return <Facebook />
            case "Linkedin":
                return <Linkedin />
            case "Instagram":
                return <Instagram />
            case "Youtube":
                return <Youtube />
            case "Tik-tok":
                return <TikTok />
            case "Website":
                return <Globe/>
            default: return null
        }
    }

    return (
        <footer className="footer">
            <div className="footer_first wrapper">
                <div>
                    <div className="footer_first__logo"><img src={`/logo/${xTenant}_${xCompanyID}.svg`} alt="Logo" /></div>
                    {siteDomain === 'b2b-generix.niki24.pt' || siteDomain === 'b2b-demo.niki24.pt'
                        ? <div>We’re helping connect businesses together.<br /> We’re a global leading software company offering a broad portfolio of SaaS solutions <br />and services in supply chain, finance, commerce, integration and collaboration. </div>
                        : <div>{t('niki.b2b.footer.moto1')}<br />{t('niki.b2b.footer.moto2')}</div>
                    }
                    <div className="footer_second__social">
                        {socials?.length > 0 && socials?.map(social => {
                            return <a href={social.value} key={social.id} target="_blank" rel="noopener noreferrer nofollow">{returnSocialIcon(social.matchcode)}</a>        
                        })}
                    </div>
                    <FooterLang />
                </div>
                <div className="footer_menu">
                    <div className="footer_menu__header">{t('niki.b2b.footer.shop')}</div>
                    <ul className="footer_menu__list">
                        <li><NavLink to="/all-products/">{t('niki.b2b.menu.allproducts')}</NavLink></li>
                        <li><NavLink to="/promotional-booklets/">{t('niki.b2b.menu.promotions')}</NavLink></li>
                        <li><NavLink to="/brands/">{t('niki.b2b.menu.brands')}</NavLink></li>
                        <li><NavLink to="/all-products/?show=favorites">{t('niki.b2b.menu.favorites')}</NavLink></li>
                    </ul>
                </div>
                <div className="footer_menu">
                    <div className="footer_menu__header">{t('niki.b2b.account.my')}</div>
                    <ul className="footer_menu__list">
                        <li><NavLink to="/account/">{t('niki.b2b.account.my')}</NavLink></li>
                        <li><NavLink to="/shoppingcarts/">{t('niki.b2b.cart.savedcarts')}</NavLink></li>
                        <li><NavLink to="/debts/">{t('niki.b2b.header.accountbalance')}</NavLink></li>
                        <li><NavLink to="/invoices/">{t('niki.b2b.menu.invoicesreturns')}</NavLink></li>
                        <li><NavLink to="/deliveryaddresses/">{t('niki.b2b.menu.addressescontacts')}</NavLink></li>
                        <li><NavLink to="/marketing/">{t('niki.b2b.menu.marketing')}</NavLink></li>
                    </ul>
                </div>
            </div>
            <div className="footer_second">
                <div className="footer_second__wrapper wrapper">
                    <div className="footer_second__social"/>
                    <div className="footer_second__copyright">
                        &copy; 2025 {FixCompanyName(companyName)} {t('niki.b2b.footer.niki.b2b.allrightsreserved')} | <NavLink to="/privacy-policy/">{t('niki.b2b.footer.privacypolicy')}</NavLink> | <NavLink to="/mandatory-disclosures/">{t('niki.b2b.footer.mandatorydisclosures')}</NavLink>
                    </div>
                </div>
            </div>
        </footer>
    )
}